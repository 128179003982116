@use '@angular/material' as mat;

@include mat.core();

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "variables";

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
@import "swiper/scss/zoom";

$palette: (
    50: $app-secondary-color,
    100: $app-primary-color,
    200: $app-primary-color,
    300: $app-primary-color,
    400: $app-primary-color,
    500: $app-primary-color,
    600: $app-primary-color,
    700: $app-primary-color,
    800: $app-primary-color,
    900: $app-primary-color,
   A100: $app-primary-color,
   A200: $app-primary-color,
   A400: $app-primary-color,
   A700: $app-primary-color,
   contrast: (
    50: $app-primary-color,
    100: $app-primary-color,
    200: $app-primary-color,
    300: $app-secondary-color,
    400: $app-secondary-color,
    500: $app-secondary-color,
    600: $app-secondary-color,
    700: $app-secondary-color,
    800: $app-secondary-color,
    900: $app-secondary-color,
    A100: $app-primary-color,
    A200: $app-secondary-color,
    A400: $app-secondary-color,
    A700: $app-secondary-color,
   )
);

$app-client-primary: mat.define-palette($palette);
$app-client-accent: mat.define-palette($palette);
$app-client-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-client-theme: mat.define-light-theme((
  color: (
    primary: $app-client-primary,
    accent: $app-client-accent,
    warn: $app-client-warn,
  )
));

@include mat.all-component-themes($app-client-theme);

html {
  background-color: $app-secondary-color;

  body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100vh;
    font-family: Playfair, Roboto, "Helvetica Neue", sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    touch-action: manipulation;
    -webkit-user-drag: none;
    -ms-content-zooming: none;
    overscroll-behavior-y: none;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    overflow-x: hidden;
  }
}
.hidden {
  display: none;
}
.mat-dialog-container {
  padding: 0 !important;
}
.error-dialog {
  width: 100%;
  .mat-dialog-container {
    overflow: hidden;
    padding: 1.5rem 0 !important;
  }
}

